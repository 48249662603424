import 'lazysizes';
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){    
    setupSlideMenu();
    if ($('form').length){
        $('form').each(function(){
            $(this).addClass('floatlabel-active').validate();
            $(this).find('.js-float-wrap').FloatLabel();
            $(this).find('.form-group.row').FloatLabel();
        });
    }
    // scroller functions
    $(".banners").each(function(){
        var $items = $(this).find(".banner");
        var $nav_wrap = $(this).find(".banner-navigation");
        setMinHeight($items, '.banner-stage');
        scrollSetup($items, $nav_wrap, '.banner-nav-item', 9000);
    });
    $(".scroll-wrap").each(function(){
        var $items = $(this).find(".scroller .item");
        var $nav_wrap = $(this).find(".scroll-nav");
        setMinHeight($items, '.scroller');
        scrollSetup($items, $nav_wrap, '.nav-item', 5000);
    });
    // contact
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });

    // product: purchase-form toggle
    $("#show-purchase").on('click tap', function(){
        $(this).closest("form").find('.purchase-form').show();
        $(this).hide();
    });
});

var resizeInt;
window.onresize = function(){
    clearTimeout(resizeInt);
    resizeInt = setTimeout(resetHeights, 100);
    function resetHeights(){
        $(".banners").each(function(){
            var $items = $(this).find(".banner");
            setMinHeight($items, '.banner-stage');
        });
        $(".scroll-wrap").each(function(){
            var $items = $(this).find(".scroller .item");
            setMinHeight($items, '.scroller');
        });
    }
};

export function setupSlideMenu(){
    $('#mobile-menu, #slidemenulabel').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

// Scrollers
function setMinHeight($obj, wrapper_class){
    var maxH = 0;
    $obj.parents(wrapper_class).css('minHeight', '0px');
    $obj.each(function(i, el){
        var height = Math.ceil($(el).outerHeight());
        maxH = (height > maxH)? height: maxH;
    });
    $obj.parents(wrapper_class).css('minHeight', maxH+"px");
}

function scrollSetup($items, $nav_wrap, nav_class, ms){
    if($items.length > 1)  {
        var $nav_items = $nav_wrap.find(nav_class);
        var rotInt = setInterval(function(){swap($items, $nav_items);}, ms);
        scrollNav($nav_items, $items, rotInt);
        $nav_wrap.show();
        $nav_wrap.css('visibility', 'visible');
    }
}

function swap($items, $nav_items){
    var $scroller = $items.parent();
    var $nav_wrap = $nav_items.parent();

    var $oldb = $scroller.find(".active");
    var $newb = $scroller.find(".active").next();
    var $lastb = $items.last();

    if (! $lastb.hasClass('active')){
        $oldb.removeClass('active');
        $newb.addClass('active');
        var $index = $newb.index();
        /* nav */
        $nav_wrap.find(".active").removeClass("active");
        $nav_items.eq($index).addClass("active");
    } else {
        $oldb.removeClass("active");
        $items.first().addClass("active");
        /* banner nav */
        $nav_wrap.find(".active").removeClass("active");
        $nav_items.first().addClass("active");
    }
}
function scrollNav($nav_items, $items, rotInt){
    $nav_items.each(function(index,el){
        $(el).click(function(){
            // nav
            $(this).siblings().removeClass("active");
            $(this).addClass("active");
            var $index = $(this).index();
            // scroll items
            $items.removeClass('active');
            $items.eq($index).addClass('active');
            // reset
            clearInterval(rotInt);
            rotInt = setInterval(function(){swap($items, $nav_items);}, 5000);
        });
    });
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});